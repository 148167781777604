<template>
    <div class="text-center">
        <template v-if="checkPermission('ApplicationConfigurationResource.PUT.ApplicationConfiguration')">
        <button
            @click="editAppConfig()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <template v-if="checkPermission('ApplicationConfigurationResource.DELETE.ApplicationConfiguration.id')">
        <button
            @click="deleteAppConfig()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const ApplicationConfigurationRepository = RepositoryFactory.get("appConfig");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    props: {
        appConfigVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        deleteAppConfig() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data  Application Configuration akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await ApplicationConfigurationRepository.deleteAppConfig(this.rowData.id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.appConfigVue.fetch(resolve);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Application Configuration berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data  Application Configuration gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editAppConfig() {
            this.appConfigVue.dataEdit = this.rowData;
             this.appConfigVue.showModal();
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        }
    }
}
</script>

<style>

</style>
