<template>
    <div>
        <base-header base-title="Application Configuration"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh :table-name="tableName" :on-refresh="fetch"></auto-refresh>&nbsp;&nbsp;
                            <template v-if="checkPermission('KategoriResource.POST.Kategori')">
                            <base-button-modal
                                :button-text="modalText"
                                :button-icon="modalIcon"
                                :button-name="modalName"
                                :onButtonModalClicked="showModal"
                            ></base-button-modal>
                            </template>
                            <div class="ml-2"></div>
                            <template v-if="checkPermission('ApplicationConfigurationResource.POST.ApplicationConfiguration.refreshApplicationProperties')">
                            <base-button
                                :button-text="'Refresh'"
                                :button-icon="'flaticon-refresh'"
                                :button-name="'refrsh'"
                                :onButtonModalClicked="refresh"
                            ></base-button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded">
                    <data-tables :table="table"></data-tables>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
                <form-modal
                    v-if="isShowModal"
                    :show-modal.sync="isShowModal"
                    @event="fetch"
                    :modal-name="modalName"
                    :modal-data="dataEdit"
                ></form-modal>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import AutoRefresh from "./../_general/AutoRefresh";
import BaseButtonModal from "./../_base/BaseButtonModal";
import BaseButton from "./../_base/BaseButton";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const ApplicationConfigurationRepository = RepositoryFactory.get("appConfig");
import SweetAlertTemplate from "./../_general/SweetAlert";
import BlockUI from "../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    components: {
        BaseHeader,
        BaseTitle,
        AutoRefresh,
        BaseButtonModal,
        BaseButton,
        DataTables,
        LoadSpinner,
        BtnAction,
        FormModal: () => ({
        component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                resolve(import(/* webpackChunkName: "modal-app-config" */ "./_components/FormModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            isLoaded: false,
            isShowModal: false,
            dataEdit: null,
            headTitle: "Application Configuration",
            tableName: "table_app_config",
            modalText: "Tambah Application Configuration",
            modalName: "modal_app_config",
            modalIcon: "flaticon2-add-1",
                table: {
                tableName: "table_app_config",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: "-",
                        targets: "_all"
                    },
                    {
                        targets: 2,
                        render: function(data, type, row, meta) {
                            return (data == ''|| data == null) ? 'null' : data;
                        }
                    },
                    {
                        targets: -1,
                        responsivePriority: 1
                    }
                ],
                tableColumns: [
                {
                    title: "ID",
                    data: "id"
                },
                {
                    title: "Key",
                    data: "key"
                },
                {
                    title: "Value",
                    data: "value"
                },
                {
                    title: "Actions",
                    data: "id",
                    createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                        let btnAction = Vue.extend(BtnAction);
                        let instance = new btnAction({
                            propsData: {
                                appConfigVue: vx,
                                rowData: rowData
                            }
                    });
                    instance.$mount();
                    $(cell)
                        .empty()
                        .append(instance.$el);
                    }
                }
                ],
                tableFilter: [
                    {
                        title: "Key",
                        type: "text"
                    },
                    {
                        title: "Value",
                        type: "text"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        }
    },
    watch: {
        isShowModal: function(val) {
            if(!val) {
                this.dataEdit = null;
            }
        }
    },
    methods: {
        refresh: function(){
            var vx = this;
            let instance = new SweetAlert().showConfirm({
              swalTitle: "Apakah Anda yakin?",
              swalText: "Refresh Application Configuration akan dijalankan.",
              swalType: "info",
              onConfirmButton: function () {
                blockUI.blockModal();
                vx.doRefresh();
              },
              onCancelButton: function () {
                blockUI.unblockModal();
              }
            });
        },
        async doRefresh(){
            var vx = this;

            await ApplicationConfigurationRepository.refresh()
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Berhasil Refresh Application Configuration.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                        }
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Gagal Refresh Application Configuration.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        showModal: function() {
            this.isShowModal = true;
        },
        async fetch(resolve) {
            new Promise((resolve, reject) => {
                ApplicationConfigurationRepository.get({}, resolve, reject );
            }).then(response => {
                const data = response.data;
                if (this.isLoaded) {
                    var datatable = $("#" + this.tableName).DataTable();
                    datatable.clear();
                    datatable.rows.add(data);
                    datatable.draw("full-hold");
                    if(resolve !== undefined) {
                        resolve(data);
                    }
                } else {
                    this.table.tableDataResult = data;
                    this.isLoaded = true;
                }
            }).catch(error => {
                var msg = (error.message) ? error.message : error;
                this.showToastError('Application Configuration : ' + msg);
            })

        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {
         this.fetch();
    }
}
</script>

<style>

</style>
